
export default {
    LANDING_HOME: { URL: '/', RESOURCES: [], PERMISSIONS: [] },
    LANDING_CERTIFICATIONS: { URL: '/certifications', RESOURCES: [], PERMISSIONS: [] },
    LANDING_CERTIFICATIONS_DETAILS: { URL: '/certifications/:certId', RESOURCES: [], PERMISSIONS: [] },
    LANDING_WHYUS: { URL: '/whyus', RESOURCES: [], PERMISSIONS: [] },
    LANDING_COURSES: { URL: '/courses', RESOURCES: [], PERMISSIONS: [] },
    LANDING_COURSE_DETAILS: { URL: '/courses/:courseId/:packageId', RESOURCES: [], PERMISSIONS: [] },
    LANDING_STAFF: { URL: '/staff', RESOURCES: [], PERMISSIONS: [] },
    LANDING_CONTACTUS: { URL: '/contactus', RESOURCES: [], PERMISSIONS: [] },
    LANDING_RESET_PASSWORD: { URL: '/resetpassword/:resetCode', RESOURCES: [], PERMISSIONS: [] },
    LANDING_ACTIVATION: { URL: '/activation', RESOURCES: [], PERMISSIONS: [] },
    LANDING_SIGNIN: { URL: '/signin', RESOURCES: [], PERMISSIONS: [] },
    LANDING_SIGNUP: { URL: '/signup', RESOURCES: [], PERMISSIONS: [] },
    LANDING_FORGOT_PASSWORD: { URL: '/forgotPassword', RESOURCES: [], PERMISSIONS: [] },
    LANDING_PROFILE: { URL: '/profile', RESOURCES: [], PERMISSIONS: [] },
    LANDING_TERMS_AND_CONDITIONS: { URL: '/termsandconditions', RESOURCES: [], PERMISSIONS: [] },
    LANDING_PRIVACY_POLICY: { URL: '/privacypolicy', RESOURCES: [], PERMISSIONS: [] },
    LANDING_HONOR: { URL: '/honor', RESOURCES: [], PERMISSIONS: [] },
    LANDING_CALENDAR: { URL: '/calendar', RESOURCES: [], PERMISSIONS: [] },
    LANDING_BLOGS: { URL: '/blogs', RESOURCES: [], PERMISSIONS: [] },
    LANDING_BLOG_DETAILS: { URL: '/blogs/:id', RESOURCES: [], PERMISSIONS: [] },



    USER_DASHBOARD: { URL: '/user/dashboard', RESOURCES: [], PERMISSIONS: [] },
    USER_SUBSCRIPTIONS: { URL: '/user/subscriptions', RESOURCES: [], PERMISSIONS: [] },
    USER_COURSE_DETAILS: { URL: '/user/course/details', RESOURCES: [], PERMISSIONS: [] },
    USER_COURSE_STUDY: { URL: '/user/course/study', RESOURCES: [], PERMISSIONS: [] },
    USER_PERFORMANCE: { URL: '/user/performance', RESOURCES: [], PERMISSIONS: [] },
    USER_BANK_MCQS: { URL: '/user/msqsbank', RESOURCES: [], PERMISSIONS: [] },
    USER_BANK_ESSAY: { URL: '/user/essaysbank', RESOURCES: [], PERMISSIONS: [] },
    USER_BANK_TBS: { URL: '/user/tbsbank', RESOURCES: [], PERMISSIONS: [] },
    USER_BANK_FILE: { URL: '/user/filebank', RESOURCES: [], PERMISSIONS: [] },
    USER_MOCK_EXAM: { URL: '/user/mockexam', RESOURCES: [], PERMISSIONS: [] },
    USER_TEST_KNOWLEDGE_EXAM: { URL: '/user/exam/:topicId/:examType/:questionsCount', RESOURCES: [], PERMISSIONS: [] },
    USER_FREQ_EXAM: { URL: '/user/exam/:examId', RESOURCES: [], PERMISSIONS: [] },
    USER_EXAM_LOG: { URL: '/user/exam/log', RESOURCES: [], PERMISSIONS: [] },
    USER_EXAM_CORRECTION: { URL: '/user/exams/correction/:examId/:examRoundId', RESOURCES: [], PERMISSIONS: [] },
    USER_EXAM_RESUTLS: { URL: '/user/exams/results/:examId/:examRoundId', RESOURCES: [], PERMISSIONS: [] },
    USER_SUPPORT: { URL: '/user/support', RESOURCES: [], PERMISSIONS: [] },
    USER_NOTIFICATIONS: { URL: '/user/notifications', RESOURCES: [], PERMISSIONS: [] },


    ADMIN_DASHBOARD: { URL: '/admin/dashboard', RESOURCES: ['ADMIN_DASHBOARD'], PERMISSIONS: [] },
    ADMIN_ADMINS: { URL: '/admin/admins', RESOURCES: ['USERS'], PERMISSIONS: [] },
    ADMIN_USERS: { URL: '/admin/users', RESOURCES: ['USERS'], PERMISSIONS: [] },
    ADMIN_NEWS: { URL: '/admin/news', RESOURCES: ['NEWS'], PERMISSIONS: [] },
    ADMIN_INSTRUCTORS: { URL: '/admin/instructors', RESOURCES: ['INSTRUCTORS'], PERMISSIONS: [] },
    ADMIN_TEAM_MEMBERS: { URL: '/admin/teamMembers', RESOURCES: ['INSTRUCTORS'], PERMISSIONS: [] },
    ADMIN_FAQS: { URL: '/admin/faqs', RESOURCES: ['INSTRUCTORS'], PERMISSIONS: [] },
    ADMIN_SUCCESS_STORIES: { URL: '/admin/successStories', RESOURCES: ['SUCCESS_STORIES'], PERMISSIONS: [] },
    ADMIN_ROLES: { URL: '/admin/roles', RESOURCES: ['ACCESS_MANAGE'], PERMISSIONS: [] },
    ADMIN_SUBSCRIPTIONS: { URL: '/admin/subscriptions', RESOURCES: ['SUBSCRIPTIONS'], PERMISSIONS: [] },
    ADMIN_PROMOTIONS: { URL: '/admin/promotions', RESOURCES: ['SUBSCRIPTIONS'], PERMISSIONS: [] },
    ADMIN_PACKAGES: { URL: '/admin/packages', RESOURCES: ['SUBSCRIPTIONS'], PERMISSIONS: [] },
    ADMIN_CERTIFICATIONS: { URL: '/admin/certifications', RESOURCES: ['CERTIFICATES'], PERMISSIONS: [] },
    ADMIN_COURSES: { URL: '/admin/courses', RESOURCES: ['COURSES'], PERMISSIONS: [] },
    ADMIN_ONE_DAY_COURSES: { URL: '/admin/oneDayCourses', RESOURCES: ['COURSES'], PERMISSIONS: [] },
    ADMIN_UNITS: { URL: '/admin/units', RESOURCES: ['UNITS'], PERMISSIONS: [] },
    ADMIN_TOPICS: { URL: '/admin/topics', RESOURCES: ['TOPICS'], PERMISSIONS: [] },
    ADMIN_QUESTIONS_MCQS_NEW: { URL: '/admin/questions/mcqs/new', RESOURCES: ['QUESTIONS'], PERMISSIONS: ['ADD'] },
    ADMIN_QUESTIONS_ESSAY: { URL: '/admin/questions/essay', RESOURCES: ['QUESTIONS'], PERMISSIONS: [] },
    ADMIN_QUESTIONS_MCQS: { URL: '/admin/questions/mcqs', RESOURCES: ['QUESTIONS'], PERMISSIONS: [] },
    ADMIN_QUESTIONS_TEXT: { URL: '/admin/questions/text', RESOURCES: ['QUESTIONS'], PERMISSIONS: [] },
    ADMIN_QUESTIONS_COMPOSITE: { URL: '/admin/questions/composite', RESOURCES: ['QUESTIONS'], PERMISSIONS: [] },
    ADMIN_QUESTIONS_FILE: { URL: '/admin/questions/file', RESOURCES: ['QUESTIONS'], PERMISSIONS: [] },
    ADMIN_QUESTIONS_SIZES: { URL: '/admin/sizes', RESOURCES: ['QUESTIONS'], PERMISSIONS: [] },
    ADMIN_QUESTIONS_CATEGORIES: { URL: '/admin/categories', RESOURCES: ['QUESTIONS'], PERMISSIONS: [] },
    ADMIN_QUESTIONS_DIFFICULTIES: { URL: '/admin/difficulties', RESOURCES: ['QUESTIONS'], PERMISSIONS: [] },
    ADMIN_UPLOADS_VIDEOS: { URL: '/admin/uploads/videos', RESOURCES: ['TOPICS'], PERMISSIONS: [] },
    ADMIN_UPLOADS_BOOKS: { URL: '/admin/uploads/books', RESOURCES: ['TOPICS'], PERMISSIONS: [] },
    ADMIN_REPORTS_PAYMENTS: { URL: '/admin/reports/payments', RESOURCES: ['PAYMENTS'], PERMISSIONS: [] },
    ADMIN_MANUAL_PAYMENTS: { URL: '/admin/payments/manual', RESOURCES: ['PAYMENTS'], PERMISSIONS: [] },
    ADMIN_REPORTS_CONTENTS: { URL: '/admin/reports/content/:reportOn', RESOURCES: ['ISSUES_REPORTS'], PERMISSIONS: [] },
    ADMIN_USER_PERFORMANCE: { URL: '/admin/reports/userPerformance', RESOURCES: ['STUDENT_PERFORMANCE'], PERMISSIONS: [] },
    ADMIN_SETTINGS_IPLOGS: { URL: '/admin/settings/iplogs', RESOURCES: ['SIGN_IN_LOGS'], PERMISSIONS: [] },
    ADMIN_MOCK_EXAMS: { URL: '/admin/exams/mock', RESOURCES: ['EXAMS'], PERMISSIONS: [] },
    ADMIN_EXAMS: { URL: '/admin/exams', RESOURCES: ['EXAMS'], PERMISSIONS: [] },
    ADMIN_SETTINGS_NOTIFICATIONS: { URL: '/admin/settings/notifications', RESOURCES: ['SETTINGS'], PERMISSIONS: [] },
    ADMIN_EXAM_CORRECTION: { URL: '/admin/exams/correction', RESOURCES: ['USERS_EXAMS_ROUNDS'], PERMISSIONS: [] },
    ADMIN_COURSES_CALENDAR: { URL: '/admin/calendar', RESOURCES: ['COURSES_CALENDAR'], PERMISSIONS: [] },
    ADMIN_MESSAGES: { URL: '/admin/messages', RESOURCES: ['USERS_MSGS'], PERMISSIONS: [] },
    ADMIN_HONOR_ROLL: { URL: '/admin/honor', RESOURCES: ['HONORS'], PERMISSIONS: [] },
    ADMIN_POLICY_TERMS: { URL: '/admin/policyTerms', RESOURCES: ['SETTINGS'], PERMISSIONS: [] },
    ADMIN_BLOGS: { URL: '/admin/blogs', RESOURCES: ['BLOGS'], PERMISSIONS: [] },
    ADMIN_BLOGS_CATEGORIES: { URL: '/admin/blog/categories', RESOURCES: ['BLOGS'], PERMISSIONS: [] },
    ADMIN_BLOGS_TAGS: { URL: '/admin/blog/tags', RESOURCES: ['BLOGS'], PERMISSIONS: [] },

};